import React, { useState } from "react";
import {
  Box,
  Columns,
  CustomButtonBase,
  DropdownMenu,
  Icon,
  MenuItemLink,
  Text,
} from "@envato/design-system/components";

import * as Analytics from "@envato/sso-analytics";
import { useLanguageCode } from "@envato/sso-common";

import { localisedPath } from "../../utils/i18n";

const locales: { [key: string]: string } = {
  de: "Deutsch",
  en: "English",
  es: "Español",
  fr: "Français",
  "pt-BR": "Português brasileiro",
  ru: "Pусский",
};

const View = () => {
  const currentLocale = useLanguageCode();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Analytics.Element
      trackingId="localeswitcher"
      trackingLabel={isOpen ? "hide" : "show"}
    >
      <DropdownMenu
        offset="2x"
        placement="top"
        onOpenChange={setIsOpen}
        trigger={
          <CustomButtonBase
            backgroundColor="interactive-tertiary"
            borderRadius="subtle"
            color={{
              default: "primary",
              hover: "secondary",
              active: "secondary",
              focus: "secondary",
              focusVisible: "secondary",
            }}
            role="combobox"
            padding="none"
            aria-label="Language Switcher"
          >
            <Box alignItems="center" paddingY="2x">
              <Columns alignItems="center" spacing="2x">
                <Icon name="globe"></Icon>
                <Text variant="label-large">{locales[currentLocale]}</Text>
                {isOpen ? (
                  <Icon name="chevron-up"></Icon>
                ) : (
                  <Icon name="chevron-down"></Icon>
                )}
              </Columns>
            </Box>
          </CustomButtonBase>
        }
      >
        {Object.keys(locales).map((selected) => (
          <MenuItemLink
            key={selected}
            to={localisedPath(window.location, selected as LanguageCode)}
          >
            <Text color="primary" variant="body-large">
              {locales[selected]}
            </Text>
          </MenuItemLink>
        ))}
      </DropdownMenu>
    </Analytics.Element>
  );
};

export default View;
